import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import clickUrl from 'assets/sounds/click-01.wav';

const S = {
  Container: styled.div`
    display: block;
    padding: 1rem;
  `,
};

const audioCtx = new AudioContext();

// https://github.com/mdn/webaudio-examples/blob/main/step-sequencer/index.html#L426
function Playground () {

  const [clickSample, setClickSample] = useState<AudioBuffer>();
  let isPlaying = useRef(false);

  useEffect(() => {
    const setupSample = async (audioCtx: AudioContext) => {
      return await getFile(audioCtx, clickUrl);
    };

    const getFile = async (audioContext :AudioContext, filePath: URL) => {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      return await audioContext.decodeAudioData(arrayBuffer);
    };

    setupSample(audioCtx).then((sample) => {
      setClickSample(sample);
    }).catch(error => console.error(error));

  }, []);


  const [tempoDisplay , setTempoDisplay] = useState(60.0);
  const tempo = useRef(60.0);
  const lookahead = 25.0;
  const schedulerAheadTime = 0.1

  let nextNoteTime = 0;
  let currentNote = 0;

  //https://react.dev/learn/referencing-values-with-refs
  const timerID:any = useRef(null);
  const scheduler = () => {
    while(nextNoteTime < audioCtx.currentTime + schedulerAheadTime) {
      // scheduleNote(nextNoteTime);
      playSample(nextNoteTime);
      nextNote();
    }
    timerID.current = setTimeout(scheduler, lookahead);
  }

  const nextNote = () => {
    const secondsPerBeat = 60.0 / tempo.current;

    nextNoteTime += secondsPerBeat; // Add beat length to last beat time

    // Advance the beat number, wrap to zero when reaching 4
    currentNote = (currentNote + 1) % 4;
  };

  const scheduleNote = (time: number) => {
    playSample(time);
  };

  const playSample = (time: number) => {
    const playbackRate = 1;
    const sampleSource = new AudioBufferSourceNode(audioCtx, {
      buffer: clickSample,
      playbackRate: playbackRate,
    });
    sampleSource.connect(audioCtx.destination);
    sampleSource.start(time);
    return sampleSource;
  }

  const play = (se: SyntheticEvent) => {
    se.preventDefault();

    isPlaying.current = !isPlaying.current;

    if (isPlaying.current) {
      if (audioCtx.state === "suspended") {
        audioCtx.resume().then(() => console.log('audio ctx resumed'));
      }

      currentNote = 0;
      nextNoteTime = audioCtx.currentTime;
      scheduler();
    } else {
      clearTimeout(timerID.current);
    }
  };

  const plus = (se: SyntheticEvent) => {
    se.preventDefault();
    if (tempoDisplay + 4 <= 250) setTempoDisplay(tempoDisplay + 4);
    if (tempo.current + 4 <= 250) tempo.current = tempo.current + 4;
  }

  const subtract = (se: SyntheticEvent) => {
    se.preventDefault();
    if (tempoDisplay - 4 >= 0) setTempoDisplay(tempoDisplay - 4);
    if (tempo.current - 4 >= 0) tempo.current = tempo.current - 4;
  }

  return (
    <S.Container>
      {clickSample ? 'loaded' : 'unloaded'}
      <div>
        <button onClick={plus}>+4</button>
        tempo {tempoDisplay}
        <button onClick={subtract}>-4</button>
        <button onClick={play}>▶️</button>
      </div>
    </S.Container>
);
}

export default Playground;
